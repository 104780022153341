import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getHotelBook } from '../../redux/userState';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { currencyFormat } from '../helper';
import moment from 'moment';
import { Triangle } from 'react-loader-spinner';
import {
  sendEmailWithFile,
  sendHotelConfirmationEmail,
  sendHotelReceiptEmail,
} from '../../services/service';

const HotelReceiptTemplate = () => {
  const { bookingId } = useParams();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [extraCharges, setExtraCharges] = useState(null);

  useEffect(() => {
    dispatch(getHotelBook({ hotelId: bookingId }));
  }, []);

  const currency = useMemo(() => {
    if (user.hotelBookData.data) {
      if (user.hotelBookData?.data?.stripeResponse) {
        return (user.hotelBookData?.data?.stripeResponse?.currency).toUpperCase();
      }

      if (user.hotelBookData?.data?.paypalResponse) {
        return (user.hotelBookData?.data?.paypalResponse?.purchase_units[0]?.payments?.captures[0]?.amount.currency_code).toUpperCase();
      }
    }

    return null;
  }, [user.hotelBookData]);

  const total = useMemo(() => {
    if (
      user.hotelBookData?.data?.stripeResponse?.payment_method_details?.card
        ?.amount_authorized
    ) {
      return {
        amount:
          user.hotelBookData?.data?.stripeResponse?.payment_method_details?.card
            ?.amount_authorized / 100,
        value: currencyFormat(
          user.hotelBookData?.data?.stripeResponse?.payment_method_details?.card
            ?.amount_authorized / 100
        ),
      };
    }

    if (user.hotelBookData?.data?.paypalResponse?.purchase_units[0]?.payments) {
      return {
        amount:
          user.hotelBookData?.data?.paypalResponse?.purchase_units[0]?.payments
            ?.captures[0]?.amount.value,
        value: currencyFormat(
          user.hotelBookData?.data?.paypalResponse?.purchase_units[0]?.payments
            ?.captures[0]?.amount.value
        ),
      };
    }

    return null;
  }, [user.hotelBookData]);

  // prepare json to send to the server

  const extractJsonForBackend = () => {
    return {
      hotelBookData: user.hotelBookData || null,
      bookingId: bookingId || '',
    };
  };

  const handleButtonClicks = async (action) => {
    const element = document.getElementById('receipt-container');
    let json = { action };
    json = { ...json, ...extractJsonForBackend() };

    // The following code is used to generate a PDF document from the receipt container and it should be updated

    const doc = new jsPDF();
    const margin = 10;

    try {
      const canvas = await html2canvas(element);
      const imageData = canvas.toDataURL('image/png');
      const pageWidth = doc.internal.pageSize.width;
      const imgWidth = pageWidth - margin * 2;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      doc.addImage(imageData, 'PNG', margin, margin, imgWidth, imgHeight);

      if (action === 'download') {
        doc.save(`hotel_booking_receipt_${bookingId}.pdf`);
      }

      if (action === 'email') {
        setLoading(true);
        setStatus('Sending Email...');
        // try {
        //   const pdfBlob = doc.output('blob');
        //   const formData = new FormData();
        //   formData.append(
        //     'file',
        //     pdfBlob,
        //     `hotel_booking_receipt_${bookingId}.pdf`
        //   );

        //   const response = await sendHotelReceiptEmail(formData);
        //   setStatus(response.data?.message);
        // } catch (error) {
        //   setStatus('Email could not be sent. Please try again.');
        //   console.error(error);
        // } finally {
        //   setLoading(false);
        // }
        await sendHotelReceiptEmail(json);
        setStatus('Email sent successfully');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error generating document:', error);
    }
  };

  return (
    <div className="container">
      <div
        style={{
          position: 'relative', // Necessary for absolute positioning of the close button
          width: '100%', // Full width to ensure proper alignment
          marginTop: '50px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center', // Center the download and email buttons
          }}
        >
          <button
            style={{
              padding: '10px 20px',
              margin: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: '#FAA41A',
              color: 'white',
              width: '140px', // Set a fixed width for the button
              height: '50px', // Set a fixed height for the button
            }}
            onClick={() => handleButtonClicks('download')}
          >
            Download
          </button>
          <button
            disabled={loading}
            style={{
              padding: '10px 20px',
              margin: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: '#32469A',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '140px', // Set a fixed width for the button
              height: '50px', // Set a fixed height for the button
            }}
            onClick={() => handleButtonClicks('email')}
          >
            {loading ? (
              <Triangle
                height="30"
                width="30"
                color="#FDA400"
                ariaLabel="triangle-loading"
                wrapperStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              'Send Email'
            )}
          </button>
        </div>
        <div
          style={{
            position: 'absolute', // Positioning the close button absolutely
            top: '0', // Align to the top of the container
            right: '0', // Align to the right of the container
          }}
        >
          <button
            style={{
              padding: '10px 20px',
              margin: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: '#FAA41A',
              color: 'white',
              width: '100px', // Set a fixed width for the button
              height: '40px', // Set a fixed height for the button
            }}
            onClick={() => window.close()}
          >
            Close
          </button>
        </div>
        <p
          style={{
            marginTop: '20px',
            fontSize: '16px',
            color: '#333',
            textAlign: 'center',
          }}
        >
          {status}
        </p>
      </div>

      <div id="receipt-container" className="container">
        <div
          style={{
            width: '100%',
            color: '#000000',
            margin: 'auto',
            padding: '15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div>
                <div>
                  <p style={{ fontSize: 22 }}>EXPLRAR PTE. LTD.</p>
                  <p>Company Reg. No.: 202327280C</p>
                </div>
                <br />
                <div>
                  <p>Booking No. {user.hotelBookData?.data?.bookingId}</p>
                  <p>
                    Payment date:{' '}
                    {moment(user.hotelBookData?.data?.createdAt).format(
                      'dddd, D MMMM YYYY'
                    )}
                  </p>
                </div>
              </div>
              <div>
                <img
                  src="../../../Assets/Img/svg/explrar-logo.svg"
                  alt="Company"
                  style={{ maxWidth: '150px' }}
                />
              </div>
            </div>
          </div>
          <p style={{ color: '#0056b3', fontSize: '24px', margin: '20px 0' }}>
            Receipt
          </p>
          <div style={{ border: '1px solid #666', marginTop: '20px' }}>
            <div
              style={{
                backgroundColor: '#e6e6e6',
                padding: '10px',
                fontSize: '18px',
                color: '#000000',
                fontWeight: 'bold',
              }}
            >
              Contact Info
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div
                style={{
                  flex: '1 1 50%',
                  padding: '10px',
                  boxSizing: 'border-box',
                }}
              >
                Name
              </div>
              <div
                style={{
                  flex: '1 1 50%',
                  padding: '10px',
                  boxSizing: 'border-box',
                }}
              >
                {user.hotelBookData?.data?.passengerInfo.firstName +
                  ' ' +
                  user.hotelBookData?.data?.passengerInfo.lastName}
              </div>
              <div
                style={{
                  flex: '1 1 50%',
                  padding: '10px',
                  boxSizing: 'border-box',
                }}
              >
                Email
              </div>
              <div
                style={{
                  flex: '1 1 50%',
                  padding: '10px',
                  boxSizing: 'border-box',
                }}
              >
                {user.hotelBookData?.data?.passengerInfo.email}
              </div>
            </div>
          </div>

          <div>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginTop: '20px',
                border: '1px solid black',
              }}
            >
              <thead>
                <tr
                  style={{
                    border: '1px solid black',
                    borderBottom: 'none',
                    backgroundColor: '#e6e6e6',
                    fontWeight: 'bold',
                  }}
                >
                  <th
                    style={{
                      fontWeight: 'bold',
                      padding: '8px',
                      textAlign: 'left',
                      borderRight: '1px solid black',
                    }}
                  >
                    Price Summary
                  </th>
                  <th style={{ padding: '8px', textAlign: 'left' }}>
                    Amount ({currency})
                  </th>
                </tr>
              </thead>
              <tfoot>
                <tr style={{ border: '1px solid black' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      borderRight: '1px solid black',
                    }}
                  >
                    Total paid (Inclusive of all taxes)
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    <p>
                      {currency} {total?.value}
                    </p>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <p>This receipt is automatically generated.</p>
        </div>
      </div>
    </div>
  );
};

export default HotelReceiptTemplate;
