import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getHotelBook } from '../../redux/userState';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { currencyFormat } from '../helper';
import moment from 'moment';
import { Triangle } from 'react-loader-spinner';
import {
  sendEmailWithFile,
  sendHotelConfirmationEmail,
} from '../../services/service';
import DayCalculator from '../days-between-two-dates/dayCalculateor';

const HotelBookingConfirmDownload = () => {
  const { bookingId } = useParams();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [extraCharges, setExtraCharges] = useState(null);

  useEffect(() => {
    dispatch(getHotelBook({ hotelId: bookingId }));
  }, []);

  const currency = useMemo(() => {
    if (user.hotelBookData.data) {
      if (user.hotelBookData?.data?.stripeResponse) {
        return (user.hotelBookData?.data?.stripeResponse?.currency).toUpperCase();
      }

      if (user.hotelBookData?.data?.paypalResponse) {
        return (user.hotelBookData?.data?.paypalResponse?.purchase_units[0]?.payments?.captures[0]?.amount.currency_code).toUpperCase();
      }
    }

    return null;
  }, [user.hotelBookData]);

  const isCancellable = useMemo(() => {
    if (user.hotelBookData?.data?.hotel[0]?.is_free_cancellable) {
      return true;
    }

    return false;
  }, [user.hotelBookData]);

  const total = useMemo(() => {
    if (
      user.hotelBookData?.data?.stripeResponse?.payment_method_details?.card
        ?.amount_authorized
    ) {
      return {
        amount:
          user.hotelBookData?.data?.stripeResponse?.payment_method_details?.card
            ?.amount_authorized / 100,
        value: currencyFormat(
          user.hotelBookData?.data?.stripeResponse?.payment_method_details?.card
            ?.amount_authorized / 100
        ),
      };
    }

    if (user.hotelBookData?.data?.paypalResponse?.purchase_units[0]?.payments) {
      return {
        amount:
          user.hotelBookData?.data?.paypalResponse?.purchase_units[0]?.payments
            ?.captures[0]?.amount.value,
        value: currencyFormat(
          user.hotelBookData?.data?.paypalResponse?.purchase_units[0]?.payments
            ?.captures[0]?.amount.value
        ),
      };
    }

    return null;
  }, [user.hotelBookData]);

  // ----- extract data for backend ------
  const extractData = () => {
    return {
      hotelBookData: user.hotelBookData || '',
      bookingId: bookingId || '',
    };
  };
  const handleButtonClicks = async (action) => {
    const element = document.getElementById('receipt-container');
    let json = { action };
    json = { ...json, ...extractData() };

    const doc = new jsPDF();
    const margin = 10;

    try {
      const canvas = await html2canvas(element);
      const imageData = canvas.toDataURL('image/png');
      const pageWidth = doc.internal.pageSize.width;
      const imgWidth = pageWidth - margin * 2;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      doc.addImage(imageData, 'PNG', margin, margin, imgWidth, imgHeight);

      if (action === 'download') {
        doc.save(`hotel_booking_receipt_${bookingId}.pdf`);
      }

      if (action === 'email') {
        setLoading(true);
        setStatus('Sending Email...');
        // try {
        //   const pdfBlob = doc.output('blob');
        //   const formData = new FormData();
        //   formData.append(
        //     'file',
        //     pdfBlob,
        //     `hotel_booking_receipt_${bookingId}.pdf`
        //   );

        //   const response = await sendEmailWithFile(formData);
        //   setStatus(response.data?.message);
        // } catch (error) {
        //   setStatus('Email could not be sent. Please try again.');
        //   console.error(error);
        // } finally {
        //   setLoading(false);
        // }
        await sendHotelConfirmationEmail(json);
        setStatus('Email Sent!');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error generating document:', error);
    }
  };

  return (
    <div className="container">
      <div
        style={{
          position: 'relative', // Necessary for absolute positioning of the close button
          width: '100%', // Full width to ensure proper alignment
          marginTop: '50px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center', // Center the download and email buttons
          }}
        >
          <button
            style={{
              padding: '10px 20px',
              margin: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: '#FAA41A',
              color: 'white',
              width: '140px', // Set a fixed width for the button
              height: '50px', // Set a fixed height for the button
            }}
            onClick={() => handleButtonClicks('download')}
          >
            Download
          </button>
          <button
            disabled={loading}
            style={{
              padding: '10px 20px',
              margin: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: '#32469A',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '140px', // Set a fixed width for the button
              height: '50px', // Set a fixed height for the button
            }}
            onClick={() => handleButtonClicks('email')}
          >
            {loading ? (
              <Triangle
                height="30"
                width="30"
                color="#FDA400"
                ariaLabel="triangle-loading"
                wrapperStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              'Send Email'
            )}
          </button>
        </div>
        <div
          style={{
            position: 'absolute', // Positioning the close button absolutely
            top: '0', // Align to the top of the container
            right: '0', // Align to the right of the container
          }}
        >
          <button
            style={{
              padding: '10px 20px',
              margin: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: '#FAA41A',
              color: 'white',
              width: '100px', // Set a fixed width for the button
              height: '40px', // Set a fixed height for the button
            }}
            onClick={() => window.close()}
          >
            Close
          </button>
        </div>
        <p
          style={{
            marginTop: '20px',
            fontSize: '16px',
            color: '#333',
            textAlign: 'center',
          }}
        >
          {status}
        </p>
      </div>

      <div id="receipt-container" className="container">
        <div
          style={{
            width: '100%',
            color: '#000000',
            margin: 'auto',
            padding: '15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div>
                <div>
                  <p style={{ fontSize: 22 }} className="color-blue fw-600">
                    Hotel Booking Confirmation
                  </p>
                  <p>
                    <span
                      className="fw-500"
                      style={{
                        color: 'gray',
                      }}
                    >
                      Booking No.
                    </span>{' '}
                    <span className="color-blue fw-500">
                      {user.hotelBookData?.data?.bookingId}
                    </span>
                  </p>
                  <p>
                    <span
                      className="fw-500"
                      style={{
                        color: 'gray',
                      }}
                    >
                      Date of Booking:
                    </span>{' '}
                    {moment(user.hotelBookData?.data?.createdAt).format(
                      'dddd, D MMMM YYYY'
                    )}
                  </p>
                </div>
              </div>
              <div>
                <img
                  src="../../../Assets/Img/svg/explrar-logo.svg"
                  alt="Company"
                  style={{ maxWidth: '150px' }}
                />
              </div>
            </div>
          </div>

          <div className="ps-3">
            <p className="fs-18 fw-600 color-blue-black pt-3">
              {user?.hotelBookData?.data?.hotel.length > 0
                ? user?.hotelBookData?.data.hotel[0].hotel_name
                : ''}
            </p>
            <p
              style={{
                fontSize: '16px',
              }}
            >
              Address: {user?.hotelBookData?.data?.hotel[0].address}
            </p>
          </div>

          <div
            className="border-top-gray ps-3"
            style={{
              backgroundColor: 'rgba(144, 148, 166, 0.1)',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '30px 10px',
              borderRadius: '10px',
              marginTop: '20px',
            }}
          >
            <div
              className="fw-400"
              style={{
                width: '47%',
              }}
            >
              <p className="fw-600">Booking Details</p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                <div
                  style={{
                    width: '50%',
                  }}
                >
                  <p className="fs-16 fw-600">Check in Date:</p>
                  <p
                    className="fs-20 fw-600"
                    style={{
                      color: 'gray',
                    }}
                  >
                    {moment(
                      user?.hotelBookData?.data?.bookingDetails.checkinDate
                    ).format('DD MMM, YYYY')}
                  </p>
                </div>
                <div
                  style={{
                    width: '50%',
                  }}
                >
                  <p className="fs-16 fw-600">Check out Date:</p>
                  <p
                    className="fs-20 fw-600"
                    style={{
                      color: 'gray',
                    }}
                  >
                    {moment(
                      user?.hotelBookData?.data?.bookingDetails.checkoutDate
                    ).format('DD MMM, YYYY')}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                {user.hotelBookData.data ? (
                  <div
                    style={{
                      width: '50%',
                    }}
                  >
                    <p className="fs-16 fw-600">Guest Name:</p>
                    <p
                      className="fs-20 fw-600"
                      style={{
                        color: 'gray',
                      }}
                    >
                      {user.hotelBookData.data.passengerInfo.firstName}{' '}
                      {user.hotelBookData.data.passengerInfo.lastName}
                    </p>
                  </div>
                ) : null}
                <div
                  style={{
                    width: '50%',
                  }}
                >
                  <p className="fs-16 fw-600">Number of Nights:</p>
                  <p
                    className="fs-20 fw-600"
                    style={{
                      color: 'gray',
                    }}
                  >
                    {DayCalculator(
                      moment(
                        user?.hotelBookData?.data?.bookingDetails?.checkinDate
                      ),
                      moment(
                        user?.hotelBookData?.data?.bookingDetails?.checkoutDate
                      )
                    )}{' '}
                    {DayCalculator(
                      user?.hotelBookData?.data?.bookingDetails?.checkinDate,
                      user?.hotelBookData?.data?.bookingDetails?.checkoutDate
                    ) > 1
                      ? `nights`
                      : `night`}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="fw-400"
              style={{
                width: '53%',
                paddingLeft: '20px',
                borderLeft: '1px solid #ccc',
              }}
            >
              <p className="fw-600">Room Details</p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                <div
                  style={{
                    width: '50%',
                  }}
                >
                  <p className="fs-16 fw-600">Room Type: </p>
                  <p
                    className="fs-20 fw-600"
                    style={{
                      color: 'gray',
                    }}
                  >
                    {user?.hotelBookData?.data?.room.room_name}
                  </p>
                </div>
                <div
                  style={{
                    width: '50%',
                  }}
                >
                  <p className="fs-16 fw-600">Meals:</p>
                  <p
                    className="fs-20 fw-600"
                    style={{
                      color: 'gray',
                    }}
                  >
                    Breakfast Included
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                <div
                  style={{
                    width: '50%',
                  }}
                >
                  <p className="fs-16 fw-600">Number of Rooms:</p>
                  <p
                    className="fs-20 fw-600"
                    style={{
                      color: 'gray',
                    }}
                  >
                    {user.hotelBookData.data
                      ? user.hotelBookData.data.rooms
                      : 1}
                  </p>
                </div>
                {user.hotelBookData.data ? (
                  <div
                    style={{
                      width: '50%',
                    }}
                  >
                    <p className="fs-16 fw-600">Max Occupancy(Per Room)</p>
                    <p
                      className="fs-20 fw-600"
                      style={{
                        color: 'gray',
                      }}
                    >
                      {user.hotelBookData.data.room.nr_adults} Adults
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div
            style={{
              padding: '15px',
              marginTop: '20px',
            }}
          >
            <p className="fs-18 fw-600 mb-2">Price Details</p>
            <p className="fw-600 mb-1">Total Paid:</p>
            <p className="fs-22 color-blue fw-600">
              {currency} {total ? total.value : 0}
            </p>
            <p
              className="fs-16"
              style={{
                color: 'gray',
              }}
            >
              (Inclusive of taxes)
            </p>
          </div>

          {user.hotelBookData?.data?.paidBy === 'card' ? (
            <div className="px-3 pt-2 border-top-gray mt-3">
              <p className="fs-16 fw-600 color-blue">Payment Method</p>
              <div className="d-flex align-items-center mt-2">
                <img
                  src={`../../../Assets/icons/${user.hotelBookData?.data?.stripeResponse?.payment_method_details.card?.brand}-icon.svg`}
                  alt={`${user.flightBookData?.data?.hotelBookData?.payment_method_details.card?.brand}-icon`}
                />
                <p className="fs-14 fw-600 color-blue-black opacity-70 ms-2">
                  {
                    user.hotelBookData?.data?.stripeResponse
                      ?.payment_method_details?.card?.brand
                  }
                  xxxxxxxxx
                  {
                    user.hotelBookData?.data?.stripeResponse
                      ?.payment_method_details.card?.last4
                  }
                </p>
              </div>
            </div>
          ) : user.hotelBookData?.data?.paidBy === 'paypal' ? (
            <div className="px-3 pt-2 mt-3">
              <p className="fs-16 fw-600 color-blue">Payment Method</p>
              <div className="d-flex align-items-center mt-2">
                <img
                  src={`../../../Assets/icons/paypal-icon.svg`}
                  alt={`paypal-icon`}
                />
                <p className="fs-14 fw-400 color-blue-black opacity-70 ms-2">
                  <p className="fs-14 fw-400 color-blue-black opacity-70 ms-2">
                    {user.hotelBookData?.data?.paypalResponse.payer.email_address.slice(
                      0,
                      6
                    )}
                    xxxxxxx
                    {user.hotelBookData?.data?.paypalResponse.payer.email_address.substring(
                      user.hotelBookData?.data?.paypalResponse.payer
                        .email_address.length - 8
                    )}
                  </p>
                </p>
              </div>
            </div>
          ) : (
            ''
          )}

          <div
            style={{
              padding: '15px',
              marginTop: '20px',
            }}
          >
            <p className="fw-600 fs-20">Cancellation Policy</p>
            <p className="fw-500">
              {isCancellable
                ? 'This booking is Refundable. Failure to arrive at your hotel or property will be treated as a No-Show and will incur a charge of as per the booking value (Hotel policy).'
                : 'This booking is Non-Refundable and cannot be amended or modified. Failure to arrive at your hotel or property will be treated as a No-Show and will incur a charge of 100% of the booking value (Hotel policy).'}
            </p>
          </div>

          <div
            style={{
              padding: '15px',
              marginTop: '30px',
              backgroundColor: '#f5c1c1',
              borderRadius: '20px',
            }}
          >
            <p className="fw-600 mb-1">Notes</p>
            <p className="fw-500 mb-3">
              <span
                className="fw-500"
                style={{
                  color: 'red',
                }}
              >
                Important:{' '}
              </span>
              At check-in, you must present a valid photo ID with your address
              confirming the same name as the lead guest on the booking. For
              bookings paid with a credit card, you may also need to present the
              card used to make the payment. Failure to do so may result in the
              hotel requesting additional payment or your reservation not being
              honored.
            </p>

            <ul
              style={{
                marginTop: '10px',
                padding: '0 20px',
                listStyleType: 'disc !important',
              }}
            >
              <li
                style={{
                  listStyleType: 'disc !important',
                }}
              >
                1. All rooms are guaranteed on the day of arrival. In the case
                of a no-show, your room(s) will be released and you will be
                subject to the terms and conditions of the Cancellation/No-Show
                Policy specified at the time you made the booking as well as
                noted in the Confirmation Email.
              </li>
              <li
                style={{
                  listStyleType: 'disc !important',
                }}
              >
                2. The total price for this booking does not include mini-bar
                items, telephone usage, laundry service, etc. The property will
                bill you directly.
              </li>
              <li
                style={{
                  listStyleType: 'disc !important',
                }}
              >
                3. In cases where Breakfast is included with the room rate,
                please note that certain properties may charge extra for
                children travelling with their parents. If applicable, the
                property will bill you directly. Upon arrival, if you have any
                questions, please verify with the property.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelBookingConfirmDownload;
